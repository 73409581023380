import firebase from 'firebase/compat'
import { useEffect, useMemo } from 'react'
import { LoadingHook, useLoadingValue } from '../util'

export type AuthStateHook = LoadingHook<firebase.User | null, firebase.auth.Error>

export const useAuthState = (): AuthStateHook => {
  const { error, loading, setError, setValue, value } = useLoadingValue<
    firebase.User | null,
    firebase.auth.Error
  >(() => firebase.auth().currentUser)

  useEffect(() => {
    const listener = firebase.auth().onAuthStateChanged(setValue, setError)

    return () => {
      listener()
    }
  }, [firebase.auth()])

  const resArray: AuthStateHook = [value, loading, error]
  return useMemo<AuthStateHook>(() => resArray, resArray)
}
