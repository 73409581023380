import firebase from 'firebase/compat'
import { useMemo } from 'react'
import { IMessage, IUser } from '.'

export const useUpdateReadedMessage = () => {
  const updateReadedMessage = async (user: IUser, me: IUser, tid: string, messages: IMessage[]) => {
    const users = firebase.firestore().collection('users')
    const myThread = users.doc(me.uid).collection('threads').doc(tid)
    const userThread = users.doc(user.uid).collection('threads').doc(tid)

    if (!messages || messages.length < 0) return
    if (!messages[messages.length - 1]) return
    const mid = messages[messages.length - 1].mid

    myThread.update({
      user: {
        uid: user.uid,
        readedMsg: mid,
        meta: `${user.name} ${user.lastName} ${user.address.company}`,
      },
    })

    userThread.update({
      readedMsg: mid,
    })
  }

  const resArray = [updateReadedMessage]

  return useMemo(() => resArray, resArray)
}
