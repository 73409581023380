import { Avatar } from 'components/Avatar'
import { MessageAttachmentCard } from 'components/MessageAttachmentCard'
import { IFile, IMessage } from 'hooks/collections'
import { useFromNow } from 'hooks/fromNow'
import * as React from 'react'
import './ChatMessage.scss'

export interface ChatMessageProps {
  uid: string
  photoURL?: string
  text: string
  date?: number
  files?: IFile[]
}

export function ChatMessage(props: React.PropsWithChildren<ChatMessageProps>) {
  const [fromNow] = useFromNow()

  return (
    <div className="ChatMessage" style={{ marginTop: props.photoURL ? 8 : 0 }}>
      {!!props.photoURL && <Avatar src={props.photoURL} size={28} />}
      {!props.photoURL && <div style={{ width: 28, height: 5, flexShrink: 0 }} />}
      <div className="ChatMessage__Container">
        {!!props.date && (
          <div className="ChatMessage__Date bp3-text-muted bp3-text-small">
            {fromNow(props.date)}
          </div>
        )}
        <div className="ChatMessage__Text">{props.text}</div>
        {!!props.files && (
          <div className="ChatMessage__Files">
            {props.files.map((file, index) => (
              <MessageAttachmentCard key={index} file={file} />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
