import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import './AttachmentCard.scss'
import icon from './document.png'

export interface AttachmentCardProps {
  file: File
  onClick: () => void
}

export function AttachmentCard(props: React.PropsWithChildren<AttachmentCardProps>) {
  let link: string = ''
  if (props.file.type.includes('image')) {
    link = URL.createObjectURL(props.file)
  } else {
    link = icon
  }

  return (
    <div className="AttachmentCard" onClick={props.onClick}>
      <img src={link} />
      <div className="AttachmentCard__Remove">
        <FontAwesomeIcon icon={faTrashAlt} />
      </div>
    </div>
  )
}
