import React from 'react'
import ReactDOM from 'react-dom'
import 'hooks/firebase/firebase.ts'
import App from './App'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css'
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'
import 'scss/index.scss'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
