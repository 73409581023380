import { H3 } from '@blueprintjs/core'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IUser } from 'hooks/collections'
import moment from 'moment'
import * as React from 'react'
import './UserInfo.scss'

export interface UserInfoProps {
  user?: IUser
  onEditSubscription?: (user: IUser) => void
}

export function UserInfo(props: React.PropsWithChildren<UserInfoProps>) {
  return (
    <div className="UserInfo">
      <H3 style={{ marginTop: 8 }}>Kontakt:</H3>
      <div>
        <b>E-mail:</b> {props.user?.email}
      </div>
      <div>
        <b>Numer telefonu:</b> {props.user?.address.number}{' '}
      </div>
      <H3>Dane konta:</H3>
      <div>
        <b>Koniec subskrypcji</b>:{' '}
        {!!props.user?.subscriptionDate && moment(props.user.subscriptionDate).format('DD.MM.YYYY')}{' '}
        <FontAwesomeIcon
          style={{ cursor: 'pointer' }}
          icon={faPencilAlt}
          onClick={() => (props.user ? props.onEditSubscription?.(props.user) : '')}
        />
      </div>
      <H3>Dane do faktury:</H3>
      <div>
        <b>Imię i nazwisko:</b> {props.user?.name} {props.user?.lastName}
      </div>
      <div>
        <b>Nazwa firmy:</b> {props.user?.address.company}{' '}
      </div>
      <div>
        <b>NIP:</b> {props.user?.nip}{' '}
      </div>
      <div>
        <b>Kraj:</b> {props.user?.address.country}{' '}
      </div>
      <div>
        <b>Miasto:</b> {props.user?.address.city}{' '}
      </div>
      <div>
        <b>Kod pocztowy:</b> {props.user?.address.postal}{' '}
      </div>
      <div>
        <b>Adres 1:</b> {props.user?.address.street1}{' '}
      </div>
      <div>
        <b>Adres 2:</b> {props.user?.address.street2 || 'brak'}{' '}
      </div>
    </div>
  )
}
