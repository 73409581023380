import firebase from 'firebase/compat'
import { useMemo } from 'react'
import { IUser } from '.'

export const useLikeThread = () => {
  const likeThread = (me: IUser, tid: string, isFavorite: boolean) => {
    const users = firebase.firestore().collection('users')
    const myThread = users.doc(me.uid).collection('threads').doc(tid)

    myThread.update({
      isFavorite,
    })
  }

  const resArray = [likeThread]

  return useMemo(() => resArray, resArray)
}
