import firebase from 'firebase/compat'
import { useMemo } from 'react'
import { IThread, IUser } from '.'

export const useCreateThread = () => {
  const createThread = (user: IUser, me: IUser, name: string) => {
    const now = new Date().getTime()

    const tid = now + '_' + me.uid

    const userThread: IThread = {
      tid,
      user: {
        uid: me.uid,
        readedMsg: '',
        meta: `${me.name.toLocaleLowerCase()} ${me.lastName.toLocaleLowerCase()} ${me.address.company.toLocaleLowerCase()}`,
      },
      readedMsg: '',
      lastMsg: '',
      lastMsgText: '',
      isAttachment: false,
      isFavorite: false,
      name,
    }

    const myThread: IThread = {
      tid,
      user: {
        uid: user.uid,
        readedMsg: '',
        meta: `${user.name.toLocaleLowerCase()} ${user.lastName.toLocaleLowerCase()} ${user.address.company.toLocaleLowerCase()}`,
      },
      readedMsg: '',
      isAttachment: false,
      lastMsg: '',
      lastMsgText: '',
      isFavorite: false,
      name,
    }

    const users = firebase.firestore().collection('users')

    users.doc(me.uid).collection('threads').doc(tid).set(myThread)
    users.doc(user.uid).collection('threads').doc(tid).set(userThread)

    return tid
  }

  const resArray = [createThread]

  return useMemo(() => resArray, resArray)
}
