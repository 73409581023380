import firebase from 'firebase/compat'
import { useCollectionData } from 'hooks/firebase'
import { IMessage } from '.'

export const useMessages = (uid: string, tid: string) =>
  useCollectionData<IMessage>(
    firebase
      .firestore()
      .collection('users')
      .doc(uid)
      .collection('threads')
      .doc(tid)
      .collection('messages')
  )
