import { LoadingView } from 'components'
import { ChatMessage } from 'components/ChatMessage'
import { ReadIndicator } from 'components/ReadIndicator'
import {
  IThread,
  IUser,
  useMe,
  useMessages,
  useUpdateReadedMessage,
  useUser,
} from 'hooks/collections'
import { useAuthState } from 'hooks/firebase'
import * as React from 'react'
import './ChatContainer.scss'

export interface ChatContainerProps {
  thread: IThread
  uid: string
}

export function ChatContainer(props: React.PropsWithChildren<ChatContainerProps>) {
  const ref = React.useRef<HTMLDivElement | null>(null)
  const [updateReadedMessage] = useUpdateReadedMessage()
  const [messages] = useMessages(props.uid, props.thread.tid)
  const [firstUser] = useUser(props.uid)
  const [secondUser] = useUser(props.thread.user.uid)
  const [isBottom, setIsBottom] = React.useState(false)
  const [isForced, setIsForced] = React.useState(true)

  React.useEffect(() => {
    scrollToBottom()
    if (!messages) return
    if (!firstUser) return
    if (!secondUser) return
    updateReadedMessage(firstUser, secondUser, props.thread.tid, messages)
  }, [messages])

  React.useEffect(() => {
    if (!ref.current) return
    ref.current.addEventListener('scroll', onScroll)

    return () => {
      if (!ref.current) return
      ref.current.removeEventListener('scroll', onScroll)
    }
  }, [ref])

  const photos = {
    [props.uid]: firstUser?.photoURL,
    [props.thread.user.uid]: secondUser?.photoURL,
  }

  const checkIfIsBottom = (): boolean => {
    if (!ref.current) return false
    return ref.current.scrollTop + ref.current.clientHeight >= ref.current.scrollHeight
  }

  const onScroll = () => {
    setIsBottom(checkIfIsBottom())
  }

  const scrollToBottom = () => {
    if (!ref.current) return
    if (!isForced && !isBottom) return
    if (!(messages && messages?.length > 0)) return
    setIsForced(false)
    ref.current.scrollTo({ top: ref.current.clientHeight })
  }

  return (
    <div ref={ref} className="ChatContainer">
      {messages?.map((msg, index) => {
        let photoURL = photos[msg.uid]
        let date: number | undefined = msg.date

        const prev = index > 0 ? messages[index - 1] : null

        if (prev && msg.uid === prev.uid && prev.date > msg.date - 60000) {
          photoURL = undefined
          date = undefined
        }

        const users: IUser[] = []

        if (msg.mid === props.thread.readedMsg && firstUser) users.push(firstUser)
        if (msg.mid === props.thread.user.readedMsg && secondUser) users.push(secondUser)

        return (
          <>
            <ChatMessage
              key={`${msg.mid}_${index}`}
              uid={msg.uid}
              text={msg.text}
              photoURL={photoURL}
              date={date}
              files={msg.files}
            />
            {users.length > 0 && <ReadIndicator users={users} />}
          </>
        )
      })}
    </div>
  )
}
