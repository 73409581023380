import firebase from 'firebase/compat'
import { useMemo } from 'react'
import { IFile, IMessage, IUser } from '.'

export const useInactiveUser = () => {
  const inactiveUser = async (user: IUser, isInactive: boolean) => {
    const users = firebase.firestore().collection('users')
    const userRef = users.doc(user.uid)

    userRef.update({
      isInactive,
    })
  }

  const resArray = [inactiveUser]

  return useMemo(() => resArray, resArray)
}
