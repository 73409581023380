import { Button, H1 } from '@blueprintjs/core'
import { faCross, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { useState } from 'react'
import './InitView.scss'

export interface InitViewProps {
  isAdmin?: boolean
}

export function InitView(props: React.PropsWithChildren<InitViewProps>) {
  const [selectNotify, setSelectNotify] = useState(true)

  return (
    <div className="InitView">
      {selectNotify && (
        <div className="SelectNotify">
          <div>
            {props.isAdmin &&
              'Wybierz jeden z kontaktów lub wątków widocznych w menu po lewej stronie.'}
            {!props.isAdmin && 'Wybierz jeden z wątków widocznych w menu po lewej stronie.'}
          </div>
          <Button className="circle button" minimal onClick={() => setSelectNotify(false)}>
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
      )}
    </div>
  )
}
