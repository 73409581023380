import { DataFormCheck, ErrorView, LoadingView, LoggedView, LoginView } from 'components'
import { useAuthState } from 'hooks/firebase'
import * as React from 'react'
import './AuthCheck.scss'

export interface AuthCheckProps {}

export function AuthCheck(props: React.PropsWithChildren<AuthCheckProps>) {
  const [user, loading, error] = useAuthState()

  if (loading) return <LoadingView />
  if (error) return <ErrorView error={error.message} />
  if (!user) return <LoginView />
  return <DataFormCheck />
}
