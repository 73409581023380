import { IFile } from 'hooks/collections'
import * as React from 'react'
import './MessageAttachmentCard.scss'
import icon from './document.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'

export interface MessageAttachmentCardProps {
  file: IFile
}

export function MessageAttachmentCard(props: React.PropsWithChildren<MessageAttachmentCardProps>) {
  let link: string = ''
  if (props.file.type.includes('image')) {
    link = props.file.url
  } else {
    link = icon
  }

  const onDownload = () => {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', props.file.url, true)
    xhr.responseType = 'blob'
    xhr.onload = function () {
      const urlCreator = window.URL || window.webkitURL
      const imageUrl = urlCreator.createObjectURL(this.response)
      const tag = document.createElement('a')
      tag.href = imageUrl
      tag.download = props.file.name || 'file'
      document.body.appendChild(tag)
      tag.click()
      document.body.removeChild(tag)
    }
    xhr.send()
  }

  return (
    <div className="MessageAttachmentCard" onClick={onDownload}>
      <img src={link} />
      <div className="MessageAttachmentCard__Download">
        <FontAwesomeIcon icon={faFileDownload} />
      </div>
    </div>
  )
}
