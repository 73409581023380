import { Spinner } from '@blueprintjs/core'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import './SecurityView.scss'

export interface SecurityViewProps {}

export function SecurityView(props: React.PropsWithChildren<SecurityViewProps>) {
  const [count, setCount] = React.useState(0)

  React.useEffect(() => {
    console.log(count)
    if (count > 10) return
    setTimeout(() => {
      setCount(count + 1)
    }, 50)
  }, [count])

  return (
    <div className="SecurityView">
      <div>
        <div>
          <img src="./https.png" />
        </div>
      </div>
      <div>
        <p>
          <b className="bp3-text-muted">Protokół https</b> chroni informacje o właścicielu witryny i
          jej użytkownikach, zapobiegając ich przechwyceniu czy zmianie.
        </p>
        <ul>
          <li>
            Połączenie jest zabezpieczone :{' '}
            {count >= 1 && (
              <b>
                <FontAwesomeIcon icon={faCheck} /> OK
              </b>
            )}{' '}
            {count < 1 && <Spinner size={14} />}
          </li>
          <li>
            Strona połączona z odpowiednim serwerem :{' '}
            {count >= 5 && (
              <b>
                <FontAwesomeIcon icon={faCheck} /> OK
              </b>
            )}{' '}
            {count < 5 && <Spinner size={14} />}
          </li>
          <li>
            Brak ingerencji osób trzecich :{' '}
            {count >= 9 && (
              <b>
                <FontAwesomeIcon icon={faCheck} /> OK
              </b>
            )}{' '}
            {count < 9 && <Spinner size={14} />}
          </li>
          <li>
            Bezpieczeństwo dla użytkownika :{' '}
            {count >= 4 && (
              <b>
                <FontAwesomeIcon icon={faCheck} /> OK
              </b>
            )}{' '}
            {count < 4 && <Spinner size={14} />}
          </li>
          <li>
            Szyfrowanie komunikacji , łącznie z adresami URL, historią przeglądania :{' '}
            {count >= 2 && (
              <b>
                <FontAwesomeIcon icon={faCheck} /> OK
              </b>
            )}{' '}
            {count < 2 && <Spinner size={14} />}
          </li>
        </ul>
      </div>
      <div>
        <div>
          <img src="./ssl.png" />
        </div>
      </div>
      <div>
        <p>
          <b className="bp3-text-muted">SSL</b> (Secure Sockets Layer) jest protokołem do
          zabezpieczania komunikacji odbywającej się w Internecie. Dzięki niemu, wszystkie
          informacje wysyłane pomiędzy przeglądarką internetową a serwerem są szyfrowane. Aby z
          niego skorzystać, wymagana jest instalacja certyfikatu SSL.
        </p>
        <ul>
          <li>
            Status certyfikatu ssl/tls :{' '}
            {count >= 7 && (
              <b>
                <FontAwesomeIcon icon={faCheck} /> OK
              </b>
            )}{' '}
            {count < 7 && <Spinner size={14} />}
          </li>
        </ul>
      </div>
    </div>
  )
}
