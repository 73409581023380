import { IUser } from 'hooks/collections'
import * as React from 'react'
import './ReadIndicator.scss'

export interface ReadIndicatorProps {
  users?: IUser[]
}

export function ReadIndicator(props: React.PropsWithChildren<ReadIndicatorProps>) {
  return (
    <div className="ReadIndicator">
      <div className="ReadIndicator__Line" />
      <div className="ReadIndicator__Text">przeczytane</div>
      {props.users?.map((user, index) => (
        <div key={index} className="ReadIndicator__Avatar">
          <img src={user.photoURL} />
        </div>
      ))}
    </div>
  )
}
