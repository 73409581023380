import { useState, useMemo } from 'react'
import firebase from 'firebase/compat'
import { GoogleAuthProvider } from 'firebase/auth'
import { GoogleActionHook } from './types'

const provider = new GoogleAuthProvider()

export const useSignInWithGoogle = (): GoogleActionHook => {
  const [error, setError] = useState<firebase.FirebaseError>()
  const [loading, setLoading] = useState<boolean>(false)

  const signInWithGoogle = async () => {
    setLoading(true)
    try {
      await firebase.auth().signInWithPopup(provider)
      setLoading(false)
    } catch (err: any) {
      setError(err)
      setLoading(false)
    }
  }

  const resArray: GoogleActionHook = [signInWithGoogle, loading, error]
  return useMemo<GoogleActionHook>(() => resArray, resArray)
}
