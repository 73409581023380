import { Card } from '@blueprintjs/core'
import * as React from 'react'
import './ErrorView.scss'

export interface ErrorViewProps {
  error: string
}

export function ErrorView(props: React.PropsWithChildren<ErrorViewProps>) {
  return (
    <div className="ErrorView">
      <Card>{props.error}</Card>
    </div>
  )
}
