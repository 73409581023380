import { useState, useMemo } from 'react'
import firebase from 'firebase/compat'
import { SignOutActionHook } from './types'

export const useSignOut = (): SignOutActionHook => {
  const [error, setError] = useState<firebase.FirebaseError>()
  const [loading, setLoading] = useState<boolean>(false)

  const signOut = async () => {
    setLoading(true)
    try {
      await firebase.auth().signOut()
      setLoading(false)
    } catch (err: any) {
      setError(err)
      setLoading(false)
    }
  }

  const resArray: SignOutActionHook = [signOut, loading, error]
  return useMemo<SignOutActionHook>(() => resArray, resArray)
}
