import firebase from 'firebase/compat'
import { useMemo } from 'react'
import { IFile, IMessage, IUser } from '.'

export const useAddMessage = () => {
  const addMessage = async (user: IUser, me: IUser, tid: string, text: string, files: File[]) => {
    const now = new Date().getTime()

    const mid = now + '_' + me.uid

    const storageRef = firebase.storage().ref()

    const _files = await Promise.all(
      files.map(async (file, index): Promise<IFile> => {
        const fid = mid + '_' + index
        const child = storageRef.child(fid)
        await child.put(file)
        const url = await child.getDownloadURL()
        const type = file.type
        const name = file.name

        return { url, type, name, date: now }
      })
    )

    const message: IMessage = {
      mid,
      uid: me.uid,
      text,
      date: now,
      files: _files,
    }

    const users = firebase.firestore().collection('users')
    const myThread = users.doc(me.uid).collection('threads').doc(tid)
    const userThread = users.doc(user.uid).collection('threads').doc(tid)

    const myAttachments = users.doc(me.uid).collection('attachments')

    const myMessages = myThread.collection('messages')
    const userMessages = userThread.collection('messages')

    myMessages.doc(mid).set(message)
    userMessages.doc(mid).set(message)

    await Promise.all(
      _files.map((file) => {
        myAttachments.add(file)
      })
    )

    const myUpdate = {
      lastMsg: mid,
      lastMsgText: text,
      // readedMsg: mid,
    }

    //@ts-ignore
    if (files.length > 0) myUpdate.isAttachment = true

    myThread.update(myUpdate)

    const userUpdate = {
      lastMsg: mid,
      lastMsgText: text,
      // user: {
      //   uid: me.uid,
      //   readedMsg: mid,
      //   meta: `${me.name} ${me.lastName} ${me.address.company}`,
      // },
    }

    //@ts-ignore
    if (files.length > 0) userUpdate.isAttachment = true

    userThread.update(userUpdate)
  }

  const resArray = [addMessage]

  return useMemo(() => resArray, resArray)
}
