import { Button } from '@blueprintjs/core'
import { Avatar } from 'components/Avatar'
import { IUser, useMe } from 'hooks/collections'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationTriangle,
  faComment as fasComment,
  faUserAltSlash,
} from '@fortawesome/free-solid-svg-icons'
import { faComment as farComment } from '@fortawesome/free-regular-svg-icons'
import * as React from 'react'
import { Tooltip2 } from '@blueprintjs/popover2'
import './ContactItem.scss'

export interface ContactItemProps {
  contact: IUser
  onClick?: (uid: string) => void
  onPayment?: (user: IUser) => void
  createNewThread?: (user: IUser) => void
  selected?: boolean
}

export function ContactItem(props: React.PropsWithChildren<ContactItemProps>) {
  const showAlert =
    props.contact.subscriptionDate === undefined ||
    props.contact.subscriptionDate < new Date().getTime() ||
    props.contact.isInactive

  return (
    <div className={`ContactItem ${props.selected ? 'selected' : ''}`}>
      <Tooltip2 content="Profil użytkownika" position="bottom" intent="primary">
        <Avatar
          src={props.contact.photoURL}
          size={32}
          onClick={() => props.onClick?.(props.contact.uid)}
        />
      </Tooltip2>
      <Tooltip2
        targetTagName="div"
        className="ContactItem__Content"
        content="Profil użytkownika"
        position="bottom"
        intent="primary"
      >
        <div className="ContactItem__Content" onClick={() => props.onClick?.(props.contact.uid)}>
          <div className="bp3-text-small">{props.contact.address.company}</div>
          <div className="bp3-text-small bp3-text-muted">NIP: {props.contact.nip}</div>
          <div className="bp3-text-small bp3-text-muted">
            {props.contact.name} {props.contact.lastName}
          </div>
        </div>
      </Tooltip2>
      <div className="ContactItem__Icons">
        {showAlert && (
          <Tooltip2
            content={props.contact.isInactive ? 'Użytkownik nieaktywny' : 'Zaakceptuj płatność'}
            position="bottom"
            intent="warning"
          >
            <Button
              className="circle-button"
              minimal
              intent="warning"
              onClick={
                props.contact.isInactive ? undefined : () => props.onPayment?.(props.contact)
              }
            >
              <FontAwesomeIcon
                icon={props.contact.isInactive ? faUserAltSlash : faExclamationTriangle}
              />
            </Button>
          </Tooltip2>
        )}
        <Tooltip2 content="Napisz nową wiadomość" position="bottom" intent="primary">
          <Button
            className="circle-button"
            minimal
            onClick={() => props.createNewThread?.(props.contact)}
          >
            <FontAwesomeIcon icon={farComment} />
          </Button>
        </Tooltip2>
      </div>
    </div>
  )
}
