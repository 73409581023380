import { Button } from '@blueprintjs/core'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IUser, useCreateThread, useMe } from 'hooks/collections'
import { useAdminData } from 'hooks/collections/adminData'
import * as React from 'react'
import './WriteToAdminButton.scss'

export interface WriteToAdminButtonProps {
  createNewThread?: (user: IUser) => void
}

export function WriteToAdminButton(props: React.PropsWithChildren<WriteToAdminButtonProps>) {
  const [admin] = useAdminData()

  if (!admin) return null

  const onClick = () => {
    const adminData: IUser = {
      uid: admin.uid,
      name: '',
      lastName: '',
      email: '',
      nip: '',
      photoURL: '',
      address: {
        company: '',
        country: '',
        city: '',
        number: '',
        postal: '',
        street1: '',
        street2: '',
      },
    }

    props.createNewThread?.(adminData)
  }

  return (
    <Button className="WriteToAdminButton circle-button" intent="primary" onClick={onClick}>
      <FontAwesomeIcon icon={faPlus} size="2x" />
    </Button>
  )
}
