import { Button, ControlGroup, InputGroup, Menu, MenuItem } from '@blueprintjs/core'
import { LoadingView, ErrorView } from 'components'
import { ContactItem } from 'components/ContactItem'
import { IUser, useContacts, useMe } from 'hooks/collections'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSortNumericUp,
  faSortNumericDown,
  faSortAlphaDown,
  faSortAlphaUp,
} from '@fortawesome/free-solid-svg-icons'
import { Popover2, Tooltip2 } from '@blueprintjs/popover2'
import * as React from 'react'
import './ContactsList.scss'

export interface ContactsListProps {
  onSelect?: (uid: string) => void
  onPayment?: (user: IUser) => void
  createNewThread?: (user: IUser) => void
  selectedUser: string
}

const sortingIcons = {
  'alpha-up': faSortAlphaUp,
  'alpha-down': faSortAlphaDown,
  'time-up': faSortNumericUp,
  'time-down': faSortNumericDown,
}

export function ContactsList(props: React.PropsWithChildren<ContactsListProps>) {
  const [contacts, loading, error] = useContacts()
  const [textFilter, setTextFilter] = React.useState('')
  const [sorting, setSorting] = React.useState<'alpha-up' | 'alpha-down' | 'time-up' | 'time-down'>(
    'time-down'
  )
  const [me] = useMe()

  if (loading) return <LoadingView />
  if (error) return <ErrorView error={error.message} />

  if (!me) return <LoadingView />

  const _filter = textFilter.toLocaleLowerCase().split(' ')
  const _contacts = contacts?.filter((contact) => {
    if (me?.uid === contact.uid) return false

    const text = `${contact.name.toLocaleLowerCase()} ${contact.lastName.toLocaleLowerCase()} ${contact.address.company.toLocaleLowerCase()}`
    let flag = true
    _filter.forEach((f) => {
      if (!text.includes(f)) {
        flag = false
      }
    })
    return flag
  })

  switch (sorting) {
    case 'alpha-down':
      _contacts?.sort((a, b) => {
        const _a = `${a.name} ${a.lastName} ${a.address.company}`
        const _b = `${b.name} ${b.lastName} ${b.address.company}`

        if (_a === _b) return 0
        if (_a < _b) return -1
        return 1
      })
      break
    case 'alpha-up':
      _contacts?.sort((a, b) => {
        const _a = `${a.name} ${a.lastName} ${a.address.company}`
        const _b = `${b.name} ${b.lastName} ${b.address.company}`

        if (_a === _b) return 0
        if (_a > _b) return -1
        return 1
      })
      break
    case 'time-down':
      // nothing
      break
    case 'time-up':
      _contacts?.reverse()
      break

    default:
      break
  }

  return (
    <div className="ContactsList">
      <ControlGroup fill>
        <InputGroup
          fill
          placeholder="Wpisz nazwę klienta"
          value={textFilter}
          onChange={(e) => setTextFilter(e.target.value)}
        />
        <Popover2
          content={
            <Menu>
              <MenuItem text="alfabetycznie - rosnąco" onClick={() => setSorting('alpha-down')} />
              <MenuItem text="alfabetycznie - malejąco" onClick={() => setSorting('alpha-up')} />
              <MenuItem text="od najnowszych" onClick={() => setSorting('time-up')} />
              <MenuItem text="od najstarszych" onClick={() => setSorting('time-down')} />
            </Menu>
          }
        >
          <Button>
            <FontAwesomeIcon icon={sortingIcons[sorting]} />
          </Button>
        </Popover2>
      </ControlGroup>
      {_contacts?.map((contact, index) => (
        <ContactItem
          key={index}
          contact={contact as IUser}
          onClick={props.onSelect}
          onPayment={props.onPayment}
          createNewThread={props.createNewThread}
          selected={contact.uid === props.selectedUser}
        />
      ))}
    </div>
  )
}
