import { H3, HTMLTable } from '@blueprintjs/core'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useUserAttachments } from 'hooks/collections/useUserAttachments'
import moment from 'moment'
import * as React from 'react'
import './UserAttachments.scss'

export interface UserAttachmentsProps {
  uid: string
}

export function UserAttachments(props: React.PropsWithChildren<UserAttachmentsProps>) {
  const [attachments] = useUserAttachments(props.uid)

  const onDownload = (url: string, name?: string) => {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', url, true)
    xhr.responseType = 'blob'
    xhr.onload = function () {
      const urlCreator = window.URL || window.webkitURL
      const imageUrl = urlCreator.createObjectURL(this.response)
      const tag = document.createElement('a')
      tag.href = imageUrl
      tag.download = name || 'file'
      document.body.appendChild(tag)
      tag.click()
      document.body.removeChild(tag)
    }
    xhr.send()
  }

  return (
    <div className="UserAttachments">
      <H3 style={{ marginTop: 8 }}>Załączniki użytkownika:</H3>
      <HTMLTable striped>
        <thead>
          <tr>
            <th>Nazwa</th>
            <th>Data wysłania</th>
            <th>Pobierz</th>
          </tr>
        </thead>
        <tbody>
          {attachments?.map((file, index) => (
            <tr key={index}>
              <td>{file.name}</td>
              <td>{moment(file.date).format('DD.MM.YYYY HH:mm')}</td>
              <td className="centered">
                <FontAwesomeIcon
                  className="bp3-text-muted"
                  icon={faFileDownload}
                  onClick={() => onDownload(file.url, file.name)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </HTMLTable>
    </div>
  )
}
