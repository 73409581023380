import firebase from 'firebase/compat'
import { useMemo, useState } from 'react'
import { SetDocHook } from './types'

export const useSetDoc = (collection: string) => {
  const [error, setError] = useState<firebase.FirebaseError>()
  const [loading, setLoading] = useState<boolean>(false)

  const setDoc = async (doc: string, payload: any) => {
    setLoading(true)
    try {
      await firebase.firestore().collection(collection).doc(doc).set(payload)
      setLoading(false)
    } catch (error: any) {
      setError(error)
      setLoading(false)
    }
  }

  const resArray: SetDocHook = [setDoc, loading, error]
  return useMemo<SetDocHook>(() => resArray, resArray)
}
