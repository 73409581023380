import { ErrorView, LoggedView, LoginView, UserDataFormView } from 'components'
import { useMe } from 'hooks/collections'
import * as React from 'react'
import './DataFormCheck.scss'

export interface DataFormCheckProps {}

export function DataFormCheck(props: React.PropsWithChildren<DataFormCheckProps>) {
  const [me, loading, error] = useMe()

  if (loading) return <LoginView />
  if (error) return <ErrorView error={error.message} />

  if (!me) return <UserDataFormView />

  return <LoggedView />
}
