import { Button, ControlGroup, H2, H3, H5, Label } from '@blueprintjs/core'
import { LoadingView, ErrorView } from 'components'
import { Avatar } from 'components/Avatar'
import { IUser, useInactiveUser, useMe, useUser } from 'hooks/collections'
import moment from 'moment'
import * as React from 'react'
import './ProfileView.scss'
import { UserAttachments } from './UserAttachments'
import { UserInfo } from './UserInfo'

export interface ProfileViewProps {
  uid: string
  onPayment?: (user: IUser) => void
  createNewThread?: (user: IUser) => void
  onEditSubscription?: (user: IUser) => void
}

export function ProfileView(props: React.PropsWithChildren<ProfileViewProps>) {
  const [user, loading, error] = useUser(props.uid)
  const [inactiveUser] = useInactiveUser()
  const [me] = useMe()
  const [tab, setTab] = React.useState<'info' | 'attachments'>('info')

  if (loading) return <LoadingView />
  if (error) return <ErrorView error={error.message} />

  if (!user) return <LoadingView />
  if (!me) return <LoadingView />

  return (
    <div className="ProfileView">
      <div className="UnderAvatar"></div>
      <Avatar src={user?.photoURL} size={100} />
      <H2>
        {user?.name} {user?.lastName}
      </H2>
      <ControlGroup fill style={{ flexWrap: 'wrap' }}>
        <Button
          intent="primary"
          onClick={() => props.createNewThread?.(user)}
          disabled={me.uid === props.uid}
        >
          Wyślij wiadomość
        </Button>
        <Button
          intent="success"
          onClick={() => user && props.onPayment?.(user)}
          disabled={!(!user.subscriptionDate || user.subscriptionDate < new Date().getTime())}
        >
          Zatwierdź płatność
        </Button>
        {tab === 'info' && (
          <Button intent="primary" onClick={() => setTab('attachments')}>
            Lista załączników
          </Button>
        )}
        {tab === 'attachments' && (
          <Button intent="primary" onClick={() => setTab('info')}>
            Dane użytkonwika
          </Button>
        )}
        <Button
          intent={user.isInactive ? 'success' : 'danger'}
          onClick={() => user && inactiveUser(user, !user.isInactive)}
          disabled={me.uid === props.uid}
        >
          {user.isInactive ? 'Aktywuj' : 'Dezaktywuj'} użytkownika
        </Button>
      </ControlGroup>
      {tab === 'info' && <UserInfo user={user} onEditSubscription={props.onEditSubscription} />}
      {tab === 'attachments' && <UserAttachments uid={user.uid} />}
    </div>
  )
}
