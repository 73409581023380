import { useMemo } from 'react'
import 'moment/locale/pl'
import moment from 'moment'

export const useFromNow = () => {
  const fromNow = (date: number) => {
    return moment(date).locale('pl').calendar()
  }

  const resArray = [fromNow]

  return useMemo(() => resArray, resArray)
}
