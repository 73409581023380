import { ControlGroup, InputGroup, Button, Menu, MenuItem } from '@blueprintjs/core'
import { LoadingView, ErrorView } from 'components'
import { ThreadItem } from 'components/ThreadItem'
import { IThread, IUser, useMe, useThreads } from 'hooks/collections'
import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons'
import { faStar as fasStar } from '@fortawesome/free-solid-svg-icons'
import {
  faSortNumericUp,
  faSortNumericDown,
  faSortAlphaDown,
  faSortAlphaUp,
} from '@fortawesome/free-solid-svg-icons'
import { Popover2, Tooltip2 } from '@blueprintjs/popover2'
import './ThreadsList.scss'
import { WriteToAdminButton } from 'components/WriteToAdminButton'

export interface ThreadsListProps {
  onSelect?: (id: string) => void
  createNewThread?: (user: IUser) => void
  selectedThread: string
}

const sortingIcons = {
  'alpha-up': faSortAlphaUp,
  'alpha-down': faSortAlphaDown,
  'time-up': faSortNumericUp,
  'time-down': faSortNumericDown,
}

export function ThreadsList(props: React.PropsWithChildren<ThreadsListProps>) {
  const [threads, loading, error] = useThreads()
  const [textFilter, setTextFilter] = React.useState('')
  const [likeFilter, setLikeFilter] = React.useState(false)
  const [sorting, setSorting] = React.useState<'alpha-up' | 'alpha-down' | 'time-up' | 'time-down'>(
    'time-down'
  )
  const [me] = useMe()

  if (loading) return <LoadingView />
  if (error) return <ErrorView error={error.message} />

  if (!me) return <LoadingView />

  const isAdmin = me.admin || false

  const _filter = textFilter.toLocaleLowerCase().split(' ')
  const _threads = threads?.filter((thread) => {
    let flag = true

    _filter.forEach((f) => {
      if (!thread.user.meta?.includes(f)) {
        flag = false
      }
    })

    if (likeFilter && !thread.isFavorite) flag = false

    return flag
  })

  switch (sorting) {
    case 'alpha-down':
      _threads?.sort((a, b) => {
        if (!a.user.meta || !b.user.meta) return 1
        if (a.user.meta === b.user.meta) return 0
        if (a.user.meta < b.user.meta) return -1
        return 1
      })
      break
    case 'alpha-up':
      _threads?.sort((a, b) => {
        if (!a.user.meta || !b.user.meta) return 1
        if (a.user.meta === b.user.meta) return 0
        if (a.user.meta > b.user.meta) return -1
        return 1
      })
      break
    case 'time-down':
      // nothing
      break
    case 'time-up':
      _threads?.reverse()
      break

    default:
      break
  }

  return (
    <div className="ThreadsList">
      <ControlGroup fill>
        <InputGroup
          fill
          placeholder="Wpisz nazwę wątku"
          value={textFilter}
          onChange={(e) => setTextFilter(e.target.value)}
        />

        <Popover2
          content={
            <Menu>
              <MenuItem text="alfabetycznie - rosnąco" onClick={() => setSorting('alpha-down')} />
              <MenuItem text="alfabetycznie - malejąco" onClick={() => setSorting('alpha-up')} />
              <MenuItem text="od najnowszych" onClick={() => setSorting('time-up')} />
              <MenuItem text="od najstarszych" onClick={() => setSorting('time-down')} />
            </Menu>
          }
        >
          <Button>
            <FontAwesomeIcon icon={faSortNumericUp} />
          </Button>
        </Popover2>
        {!likeFilter && (
          <Tooltip2 content="Tylko ważne" position="bottom" intent="primary">
            <Button onClick={() => setLikeFilter(!likeFilter)}>
              <FontAwesomeIcon icon={farStar} />
            </Button>
          </Tooltip2>
        )}
        {likeFilter && (
          <Tooltip2 content="Wszystkie" position="bottom" intent="primary">
            <Button onClick={() => setLikeFilter(!likeFilter)}>
              <FontAwesomeIcon icon={fasStar} />
            </Button>
          </Tooltip2>
        )}
      </ControlGroup>
      {_threads?.map((thread, index) => (
        <ThreadItem
          key={index}
          thread={thread as IThread}
          onClick={props.onSelect}
          selected={thread.tid === props.selectedThread}
        />
      ))}
      {!isAdmin && <WriteToAdminButton createNewThread={props.createNewThread} />}
    </div>
  )
}
