import {
  Alert,
  Button,
  Classes,
  Dialog,
  Drawer,
  H3,
  InputGroup,
  Navbar,
  ResizeSensor,
} from '@blueprintjs/core'
import { Avatar } from 'components/Avatar'
import { useAuthState, useSignOut } from 'hooks/firebase'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSignOutAlt,
  faUsers,
  faArrowLeft,
  faComments as fasComments,
  faShieldAlt,
} from '@fortawesome/free-solid-svg-icons'
import * as React from 'react'
import './LoggedView.scss'
import { useState } from 'react'
import { IUser, useMe, useUpdateSubscription } from 'hooks/collections'
import { ContactsList } from 'components/ContactsList'
import { ThreadsList } from 'components/ThreadsList'
import { Tooltip2 } from '@blueprintjs/popover2'
import { ThreadView } from 'components/ThreadView'
import { ProfileView } from 'components/ProfileView'
import { InitView } from 'components/InitView'
import { useCreateThread } from 'hooks/collections/createThread'
import { DatePicker } from '@blueprintjs/datetime'
import MomentLocaleUtils from 'react-day-picker/moment'
import 'moment/locale/pl'
import { SecurityView } from 'components/SecurityView'

export interface LoggedViewProps {}

export function LoggedView(props: React.PropsWithChildren<LoggedViewProps>) {
  const [user] = useAuthState()
  const [me] = useMe()
  const [signOut] = useSignOut()
  const [createThread] = useCreateThread()
  const [sideMenuIsOpen, setSideMenuIsOpen] = useState<null | 'contacts' | 'threads'>(null)
  const [withDrawer, setWithDrawer] = useState(window.innerWidth < 800)
  const [profile, setProfile] = useState<string | null>(null)
  const [thread, setThread] = useState<string | null>(null)
  const [security, setSecurity] = useState(false)
  const [paymentUser, setPaymentUser] = useState<IUser | null>(null)
  const [paymentDialog, setPaymentDialog] = useState(false)
  const [updateSubscription] = useUpdateSubscription()
  const [threadName, setThreadName] = useState('')
  const [threadNameDialog, setThreadNameDialog] = useState(false)
  const [threadNameUser, setThreadNameUser] = useState<IUser | null>(null)
  const [subscription, setSubscription] = useState(new Date().getTime())
  const [subscriptionDialog, setSubscriptionDialog] = useState(false)
  const [subscriptionUser, setSubscriptionUser] = useState<IUser | null>(null)

  const isAdmin = !!me?.admin

  const noSubscription = (me?.subscriptionDate || 0) < new Date().getTime()

  const onResize = () => setWithDrawer(window.innerWidth < 800)

  const onThreadSelect = (id: string) => {
    setProfile(null)
    setThread(id)
    setSecurity(false)
    if (withDrawer) setSideMenuIsOpen(null)
  }

  const onSecuritySelect = () => {
    setProfile(null)
    setThread(null)
    setSecurity(true)
  }

  const onThreadsClick = () => {
    setSecurity(false)
    setSideMenuIsOpen('threads')
  }

  const onContactsClick = () => {
    setSecurity(false)
    setSideMenuIsOpen('contacts')
  }

  const onContactSelect = (uid: string) => {
    setThread(null)
    setProfile(uid)
    setSecurity(false)
    if (withDrawer) setSideMenuIsOpen(null)
  }

  const onPaymentSelect = (user: IUser) => {
    setPaymentUser(user)
    setPaymentDialog(true)
  }

  const onPaymentCancel = () => {
    setPaymentDialog(false)
    setPaymentUser(null)
  }

  const onPaymentSubmit = () => {
    if (!paymentUser) return
    updateSubscription(paymentUser?.uid)
    setPaymentDialog(false)
    setPaymentUser(null)
  }

  const createNewThread = (_user: IUser) => {
    setThreadNameUser(_user)
    setThreadNameDialog(true)
  }

  const onThemeNameCancel = () => {
    setThreadNameDialog(false)
    setThreadName('')
    setThreadNameUser(null)
  }

  const onThemeNameSubmit = () => {
    setThreadNameDialog(false)
    setThreadName('')
    if (!me) return
    if (!threadName) return
    if (!threadNameUser) return
    const tid = createThread(threadNameUser, me, threadName)
    onThreadSelect(tid)
    setThreadNameUser(null)
  }

  const onEditSubscription = (_user: IUser) => {
    setSubscriptionUser(_user)
    setSubscriptionDialog(true)
  }

  const onSubscriptionCancel = () => {
    setSubscriptionDialog(false)
    setSubscription(new Date().getTime())
    setSubscriptionUser(null)
  }

  const onSubscriptionSubmit = () => {
    setSubscriptionDialog(false)
    setSubscription(new Date().getTime())

    if (!subscriptionUser) return
    if (!subscription) return
    updateSubscription(subscriptionUser.uid, subscription)

    setSubscriptionUser(null)
  }

  return (
    <ResizeSensor onResize={onResize}>
      <div className="LoggedView">
        <Navbar>
          <Navbar.Group align="left">
            {isAdmin && (
              <Button
                className="circle-button"
                minimal
                outlined
                intent={sideMenuIsOpen === 'contacts' ? 'primary' : 'none'}
                onClick={onContactsClick}
              >
                <FontAwesomeIcon icon={faUsers} />{' '}
                <span className="bp3-text-muted">Lista klientów</span>
              </Button>
            )}

            <Button
              className="circle-button"
              minimal
              outlined
              intent={sideMenuIsOpen === 'threads' ? 'primary' : 'none'}
              onClick={onThreadsClick}
            >
              <FontAwesomeIcon icon={fasComments} />{' '}
              <span className="bp3-text-muted">Wiadomości</span>
            </Button>

            <Button
              className="circle-button"
              minimal
              outlined
              intent={security ? 'success' : 'none'}
              onClick={onSecuritySelect}
            >
              <FontAwesomeIcon icon={faShieldAlt} />{' '}
              <span className="bp3-text-muted">Bezpieczeństwo</span>
            </Button>
          </Navbar.Group>
          <Navbar.Group className="Logo">
            <img src="./logo_toolbar.png" />
          </Navbar.Group>
          <Navbar.Group align="right">
            <Tooltip2 content="Twój profil" position="bottom" intent="primary">
              <Avatar
                src={user?.photoURL || undefined}
                size={34}
                onClick={() => onContactSelect(user?.uid || '')}
              />
            </Tooltip2>

            <Tooltip2 content="Wyloguj się" position="bottom" intent="primary">
              <Button className="circle-button" minimal onClick={signOut}>
                <FontAwesomeIcon icon={faSignOutAlt} />
              </Button>
            </Tooltip2>
          </Navbar.Group>
        </Navbar>

        <div className="LoggedView__Content">
          {!withDrawer && isAdmin && sideMenuIsOpen === 'contacts' && (
            <ContactsList
              onSelect={onContactSelect}
              onPayment={onPaymentSelect}
              createNewThread={createNewThread}
              selectedUser={profile || ''}
            />
          )}
          {!withDrawer && sideMenuIsOpen === 'threads' && (
            <ThreadsList onSelect={onThreadSelect} selectedThread={thread || ''} />
          )}
          {!withDrawer && sideMenuIsOpen === null && isAdmin && (
            <ContactsList
              onSelect={onContactSelect}
              onPayment={onPaymentSelect}
              createNewThread={createNewThread}
              selectedUser={profile || ''}
            />
          )}
          {!withDrawer && sideMenuIsOpen === null && !isAdmin && (
            <ThreadsList
              onSelect={onThreadSelect}
              createNewThread={createNewThread}
              selectedThread={thread || ''}
            />
          )}

          {thread !== null && !security && <ThreadView id={thread} uid={me?.uid || ''} />}
          {profile !== null && !security && (
            <ProfileView
              uid={profile}
              onPayment={onPaymentSelect}
              createNewThread={createNewThread}
              onEditSubscription={onEditSubscription}
            />
          )}
          {thread === null && profile === null && !security && <InitView isAdmin={isAdmin} />}
          {security && <SecurityView />}
        </div>

        {withDrawer && (
          <Drawer
            isOpen={sideMenuIsOpen !== null}
            onClose={() => setSideMenuIsOpen(null)}
            position="left"
            size={300}
          >
            <Navbar>
              <Navbar.Group align="left">
                {isAdmin && (
                  <>
                    <Tooltip2 content="Lista kontaktów" position="bottom" intent="primary">
                      <Button
                        className="circle-button"
                        minimal
                        outlined={sideMenuIsOpen === 'contacts'}
                        onClick={onContactsClick}
                      >
                        <FontAwesomeIcon icon={faUsers} />
                      </Button>
                    </Tooltip2>
                    <Tooltip2 content="Lista wiadomości" position="bottom" intent="primary">
                      <Button
                        className="circle-button"
                        minimal
                        style={{ marginLeft: 8 }}
                        outlined={sideMenuIsOpen === 'threads'}
                        onClick={onThreadsClick}
                      >
                        <FontAwesomeIcon icon={fasComments} />
                      </Button>
                    </Tooltip2>
                  </>
                )}
              </Navbar.Group>
              <Navbar.Group align="right">
                <Tooltip2 content="Zamknij menu boczne" position="bottom" intent="primary">
                  <Button className="circle-button" minimal onClick={() => setSideMenuIsOpen(null)}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </Button>
                </Tooltip2>
              </Navbar.Group>
            </Navbar>
            {sideMenuIsOpen === 'contacts' && (
              <ContactsList
                onSelect={onContactSelect}
                onPayment={onPaymentSelect}
                createNewThread={createNewThread}
                selectedUser={profile || ''}
              />
            )}
            {sideMenuIsOpen === 'threads' && (
              <ThreadsList
                onSelect={onThreadSelect}
                createNewThread={createNewThread}
                selectedThread={thread || ''}
              />
            )}
          </Drawer>
        )}

        <Dialog isOpen={paymentDialog} title="Zatwierdzenie płatności" onClose={onPaymentCancel}>
          <div className={Classes.DIALOG_BODY}>
            Czy chcesz zatwierdzić płatność za abonament użytkownika {paymentUser?.name}{' '}
            {paymentUser?.lastName}?
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={onPaymentCancel}>Anuluj</Button>
              <Button onClick={onPaymentSubmit} intent="success">
                Potwierdź
              </Button>
            </div>
          </div>
        </Dialog>

        <Dialog isOpen={threadNameDialog} title="Temat wiadomości" isCloseButtonShown={false}>
          <div className={Classes.DIALOG_BODY}>
            <div>Wpisz temat wiadomości</div>
            <InputGroup value={threadName} onChange={(e) => setThreadName(e.target.value)} />
          </div>

          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={onThemeNameCancel}>Anuluj</Button>
              <Button onClick={onThemeNameSubmit} intent="success">
                Potwierdź
              </Button>
            </div>
          </div>
        </Dialog>

        <Dialog
          isOpen={subscriptionDialog}
          title="Edytuj datę subskrypcji"
          isCloseButtonShown={false}
        >
          <div className={Classes.DIALOG_BODY}>
            <DatePicker
              locale="pl"
              localeUtils={MomentLocaleUtils}
              onChange={(d) => setSubscription(d.getTime())}
            />
          </div>

          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={onSubscriptionCancel}>Anuluj</Button>
              <Button onClick={onSubscriptionSubmit} intent="success">
                Potwierdź
              </Button>
            </div>
          </div>
        </Dialog>

        <Dialog
          isOpen={noSubscription && !me?.isInactive}
          title="Twoja subskrypcja wygasła"
          isCloseButtonShown={false}
        >
          <div className={Classes.DIALOG_BODY}>
            Aby nadal korzystać z <b>Aplikacji Bezpieczne Biuro Rachunkowe</b> bez zakłóceń, opłać
            swoją subskrypcję.
          </div>

          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={signOut} intent="primary">
                Zmień konto
              </Button>
            </div>
          </div>
        </Dialog>

        <Dialog
          isOpen={me?.isInactive}
          title="Twoja konto jest niekatywne"
          isCloseButtonShown={false}
        >
          <div className={Classes.DIALOG_BODY}>
            Aby nadal korzystać z <b>Aplikacji Bezpieczne Biuro Rachunkowe</b> bez zakłóceń,
            skontaktuj się z administratorem aplikacji.
          </div>

          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={signOut} intent="primary">
                Zmień konto
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    </ResizeSensor>
  )
}
