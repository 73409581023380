import firebase from 'firebase/compat'
import { useCollectionData } from 'hooks/firebase'

export interface IFile {
  url: string
  type: string
  name?: string
  date: number
}

export interface IMessage {
  mid: string
  uid: string
  text: string
  date: number
  files: IFile[]
}

export interface IThread {
  tid: string
  user: {
    uid: string
    readedMsg: string // id
    meta: string
  }
  readedMsg: string // id
  lastMsg: string // id
  lastMsgText: string // id
  isAttachment: boolean
  isFavorite: boolean
  name: string
}

export const useThreads = () =>
  useCollectionData<IThread>(
    firebase
      .firestore()
      .collection('users')
      .doc(firebase.auth().currentUser?.uid)
      .collection('threads')
  )
