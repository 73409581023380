import { Button, ControlGroup, FileInput, InputGroup, Spinner, TextArea } from '@blueprintjs/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faPaperPlane, faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'
import { Tooltip2 } from '@blueprintjs/popover2'
import * as React from 'react'
import './ChatInput.scss'
import { useState } from 'react'
import { AttachmentCard } from 'components/AttachmentCard'
import { IThread, useAddMessage, useMe, useUser } from 'hooks/collections'

export interface ChatInputProps {
  thread: IThread
}

export function ChatInput(props: React.PropsWithChildren<ChatInputProps>) {
  const [me] = useMe()
  const [user] = useUser(props.thread.user.uid)
  const [files, setFiles] = useState<File[]>([])
  const [text, setText] = useState('')
  const [addMessage] = useAddMessage()
  const [loading, setLoading] = useState(false)

  if (!user || !me) return null

  const onFilesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const _files = e.target.files
    if (!_files) return
    const _set = [...files]
    for (let i = 0; i < _files?.length; i++) {
      _set.push(_files[i])
    }
    setFiles(_set)
  }

  const onFilesRemove = (index: number) => {
    const _files = [...files]
    _files.splice(index, 1)
    setFiles(_files)
  }

  const onSubmit = async () => {
    if (!text) return
    setLoading(true)
    await addMessage(user, me, props.thread.tid, text, files)
    setText('')
    setFiles([])
    setLoading(false)
  }

  const onKeyUp = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.code === 'Enter' && !e.shiftKey) return onSubmit()
  }

  return (
    <div className="ChatInput">
      <div className="ChatInput__Attachments">
        <input type="file" onChange={onFilesChange} multiple disabled={loading} />
        <div className={`ChatInput__Input bp3-input ${loading ? 'disabled' : ''}`}>
          {files.length <= 0 && (
            <div className="ChatInput__Input__Background bp3-text-small">
              <FontAwesomeIcon icon={faCloudUploadAlt} />
              <div>
                <div>Przeciągnij i upuść aby dodać załącznik</div>
                <div>
                  Przesyłany plik zostanie zabezpieczony <b>protokołem SSL</b>
                </div>
              </div>
            </div>
          )}
          {files.length > 0 && (
            <div className="ChatInput__Input__Attachments">
              {files.map((file, index) => (
                <AttachmentCard file={file} key={index} onClick={() => onFilesRemove(index)} />
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="ChatInput__MainContainer">
        <TextArea
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyUp={onKeyUp}
          placeholder="Rozpocznij wpisywanie wiadomości..."
          disabled={loading}
        />
        {loading && <Spinner />}
        <Button className="circle-button" outlined intent="primary" onClick={onSubmit}>
          <FontAwesomeIcon icon={faPaperPlane} />
        </Button>
      </div>
    </div>
  )
}
