import { Button, FormGroup, H2, HTMLSelect, InputGroup } from '@blueprintjs/core'
import * as React from 'react'
import { useState } from 'react'
import { validate } from 'validate.js'
import countries from './countries_pl.json'
import constraints from './constraints.json'
import './UserDataFormView.scss'
import { useAuthState, useSetDoc } from 'hooks/firebase'
import { ErrorView } from 'components'

export interface UserDataFormViewProps {}

export function UserDataFormView(props: React.PropsWithChildren<UserDataFormViewProps>) {
  const [user] = useAuthState()

  const [name, setName] = useState('')
  const [lastName, setLastName] = useState('')
  const [nip, setNip] = useState('')
  const [company, setCompany] = useState('')
  const [country, setCountry] = useState('')
  const [city, setCity] = useState('')
  const [street1, setStreet1] = useState('')
  const [street2, setStreet2] = useState('')
  const [postal, setPostal] = useState('')
  const [number, setNumber] = useState('')

  const [setDoc, docLoading, docError] = useSetDoc('users')

  if (docError) return <ErrorView error={docError.message} />

  if (!user) return null

  const countryOptions = countries.map((c) => ({
    value: c.code,
    label: c.name_pl,
  }))

  const errors = validate(
    { name, lastName, nip, company, country, city, street1, street2, postal, number },
    constraints
  )

  const areErrors = !!errors

  const onSubmit = () => {
    if (areErrors) return
    setDoc(user.uid, {
      nip,
      name,
      lastName,
      email: user.email,
      address: {
        company,
        country,
        city,
        number,
        postal,
        street1,
        street2,
      },
      subscriptionDate: new Date().getTime() + 1000 * 60 * 60 * 24 * 30,
      uid: user.uid,
      photoURL: user.photoURL,
    })
  }

  return (
    <div className="UserDataFormView">
      <H2>Uzupełnij dane użytkownika:</H2>
      <FormGroup label="Imię:" labelInfo="*" helperText={errors?.['name']?.[0]}>
        <InputGroup value={name} onChange={(e) => setName(e.target.value)} />
      </FormGroup>
      <FormGroup label="Nazwisko:" labelInfo="*" helperText={errors?.['lastName']?.[0]}>
        <InputGroup value={lastName} onChange={(e) => setLastName(e.target.value)} />
      </FormGroup>
      <FormGroup label="NIP:" labelInfo="*" helperText={errors?.['nip']?.[0]}>
        <InputGroup value={nip} onChange={(e) => setNip(e.target.value)} />
      </FormGroup>
      <FormGroup label="Nazwa firmy:" labelInfo="*" helperText={errors?.['company']?.[0]}>
        <InputGroup value={company} onChange={(e) => setCompany(e.target.value)} />
      </FormGroup>
      <FormGroup label="Państwo:" labelInfo="*" helperText={errors?.['country']?.[0]}>
        <HTMLSelect
          fill
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          options={countryOptions}
        ></HTMLSelect>
      </FormGroup>
      <FormGroup label="Miasto:" labelInfo="*" helperText={errors?.['city']?.[0]}>
        <InputGroup value={city} onChange={(e) => setCity(e.target.value)} />
      </FormGroup>
      <FormGroup label="Adres 1:" labelInfo="*" helperText={errors?.['street1']?.[0]}>
        <InputGroup value={street1} onChange={(e) => setStreet1(e.target.value)} />
      </FormGroup>
      <FormGroup label="Adres 2:" helperText={errors?.['street2']?.[0]}>
        <InputGroup value={street2} onChange={(e) => setStreet2(e.target.value)} />
      </FormGroup>
      <FormGroup label="Kod pocztowy:" labelInfo="*" helperText={errors?.['postal']?.[0]}>
        <InputGroup value={postal} onChange={(e) => setPostal(e.target.value)} />
      </FormGroup>
      <FormGroup label="Numer telefonu:" labelInfo="*" helperText={errors?.['number']?.[0]}>
        <InputGroup value={number} onChange={(e) => setNumber(e.target.value)} />
      </FormGroup>

      <Button intent="primary" disabled={areErrors} onClick={onSubmit} loading={docLoading}>
        ZATWIERDŹ
      </Button>
    </div>
  )
}
