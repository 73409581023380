import { getAuth } from '@firebase/auth'
import firebase from 'firebase/compat'

const firebaseConfig = {
  apiKey: 'AIzaSyAXuvf6W3T8DMpu2RVjzsFEdgNJqyl8kDQ',
  authDomain: 'chat-mockup.firebaseapp.com',
  databaseURL: 'https://chat-mockup-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'chat-mockup',
  storageBucket: 'chat-mockup.appspot.com',
  messagingSenderId: '449047695803',
  appId: '1:449047695803:web:3b56890f4b9e491546349e',
  measurementId: 'G-HWG575RF6E',
}

firebase.initializeApp(firebaseConfig)
