import { IThread, useLikeThread, useMe, useUser } from 'hooks/collections'
import * as React from 'react'
import './ThreadItem.scss'
import { Tooltip2 } from '@blueprintjs/popover2'
import { Avatar } from 'components/Avatar'
import { Button } from '@blueprintjs/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons'
import { faStar as fasStar, faPaperclip } from '@fortawesome/free-solid-svg-icons'

export interface ThreadItemProps {
  thread: IThread
  onClick?: (id: string) => void
  selected?: boolean
}

export function ThreadItem(props: React.PropsWithChildren<ThreadItemProps>) {
  const [user] = useUser(props.thread.user.uid)
  const [me] = useMe()
  const [likeThread] = useLikeThread()

  if (!user || !me) return null

  const like = () => {
    likeThread(me, props.thread.tid, true)
  }

  const unlike = () => {
    likeThread(me, props.thread.tid, false)
  }

  const newMessage = props.thread.readedMsg !== props.thread.lastMsg

  return (
    <div
      className={`ThreadItem ${props.selected ? 'selected' : ''} ${
        newMessage ? 'new-message' : ''
      }`}
    >
      <Tooltip2 content="Zobacz wątek" position="bottom" intent="primary">
        <Avatar src={user.photoURL} size={32} onClick={() => props.onClick?.(props.thread.tid)} />
      </Tooltip2>

      <Tooltip2
        targetTagName="div"
        className="ThreadItem__Content"
        content="Zobacz wątek"
        position="bottom"
        intent="primary"
      >
        <div className="ThreadItem__Content" onClick={() => props.onClick?.(props.thread.tid)}>
          <div className="bp3-text-small">{props.thread.name}</div>
          <div className="bp3-text-small bp3-text-muted">{user.address.company}</div>
          <div className="bp3-text-small bp3-text-muted">
            {user.name} {user.lastName}
          </div>
        </div>
      </Tooltip2>
      <div className="ThreadItem__Icons">
        {props.thread.isAttachment && (
          <Tooltip2 content="W wątku znajduje się załącznik" position="bottom" intent="primary">
            <Button className="circle-button" minimal>
              <FontAwesomeIcon icon={faPaperclip} />
            </Button>
          </Tooltip2>
        )}
        {!props.thread.isFavorite && (
          <Tooltip2 content="Oznacz jako ważne" position="bottom" intent="primary">
            <Button className="circle-button" minimal onClick={like}>
              <FontAwesomeIcon icon={farStar} />
            </Button>
          </Tooltip2>
        )}
        {props.thread.isFavorite && (
          <Tooltip2 content="Odznacz jako ważne" position="bottom" intent="primary">
            <Button className="circle-button" minimal onClick={unlike}>
              <FontAwesomeIcon icon={fasStar} />
            </Button>
          </Tooltip2>
        )}
      </div>
    </div>
  )
}
