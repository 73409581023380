import firebase from 'firebase/compat'
import { useDocumentData } from 'hooks/firebase'

export interface IUser {
  email: string
  lastName: string
  name: string
  nip: string
  address: {
    company: string
    country: string
    city: string
    number: string
    postal: string
    street1: string
    street2: string
  }
  admin?: boolean
  subscriptionDate?: number
  uid: string
  photoURL: string
  lastMessage?: number
  isInactive?: boolean
}

export const useMe = () =>
  useDocumentData<IUser>(
    firebase.firestore().collection('users').doc(firebase.auth().currentUser?.uid)
  )
