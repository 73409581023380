import * as React from 'react'
import './Avatar.scss'

export interface AvatarProps {
  src?: string
  alt?: string
  size?: string | number
  onClick?: () => void
}

export function Avatar(props: React.PropsWithChildren<AvatarProps>) {
  return (
    <button
      className="Avatar"
      style={{
        width: props.size,
        height: props.size,
      }}
      onClick={props.onClick}
    >
      <img src={props.src} alt={props.alt || props.src || 'avatar'} />
    </button>
  )
}
