import { ChatContainer } from 'components/ChatContainer'
import { ChatInput } from 'components/ChatInput'
import { IThread, useMe, useThread, useUser } from 'hooks/collections'
import * as React from 'react'
import './ThreadView.scss'

export interface ThreadViewProps {
  id: string
  uid: string
}

export function ThreadView(props: React.PropsWithChildren<ThreadViewProps>) {
  const [thread] = useThread(props.uid, props.id)

  if (!thread) return null

  return (
    <div className="ThreadView">
      <ChatInput thread={thread} />
      <ChatContainer thread={thread} uid={props.uid} />
    </div>
  )
}
