import { Button, H2 } from '@blueprintjs/core'
import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGoogle } from '@fortawesome/free-brands-svg-icons'
import './LoginView.scss'
import { useSignInWithGoogle } from 'hooks/firebase'

export interface LoginViewProps {}

export function LoginView(props: React.PropsWithChildren<LoginViewProps>) {
  const [signIn, loading, error] = useSignInWithGoogle()

  return (
    <div className="LoginView">
      <img src="./logo.png" />
      <H2>Zaloguj się do aplikacji</H2>
      <Button large onClick={signIn} loading={loading}>
        <FontAwesomeIcon icon={faGoogle} /> ZALOGUJ
      </Button>
    </div>
  )
}
