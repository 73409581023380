import { Spinner } from '@blueprintjs/core'
import * as React from 'react'
import './LoadingView.scss'

export interface LoadingViewProps {}

export function LoadingView(props: React.PropsWithChildren<LoadingViewProps>) {
  return (
    <div className="LoadingView">
      <Spinner size={200} />
    </div>
  )
}
