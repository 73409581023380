import firebase from 'firebase/compat'
import { useMemo } from 'react'

export const useUpdateSubscription = () => {
  const updateSubscription = (uid: string, time?: number) => {
    const users = firebase.firestore().collection('users')

    users.doc(uid).update({
      subscriptionDate: time || new Date().getTime() + 1000 * 60 * 60 * 24 * 30,
    })
  }

  const resArray = [updateSubscription]

  return useMemo(() => resArray, resArray)
}
